import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { createBrowserHistory } from "history";
import GoogleLogin from "./GoogleLogin";
import GlobalHelmet from "./../../GlobalHelmet";
import Common from "./../../common.json";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mb100: { marginBottom: "100px" },
}));

export default function SignIn() {
  const classes = useStyles();
  const [submitDisabled, setsubmitDisabled] = useState(false);
  const history = createBrowserHistory({ forceRefresh: true });
  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Mobile Number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .test(
        "len",
        "Mobile Number must be exactly 10 characters",
        (val) => val.length === 10
      ),
  });
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  function onSubmit(fdata) {
    // display form data on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null));
    setsubmitDisabled(true);

    history.push("/otp/" + fdata.mobile);
  }

  return (
    <Container component="main" maxWidth="xs">
      <GlobalHelmet {...Common?.login} />
      <CssBaseline />
      <div className={"bodycontainer " + classes.mb100}>
        <div className={"upperform " + classes.paper}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
            onReset={reset}
          >
            <label>Mobile Number</label>
            <input
              variant="outlined"
              margin="normal"
              id="mobile"
              label="Mobile number"
              name="mobile"
              autoComplete="Mobile Number"
              autoFocus
              ref={register}
              className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.mobile?.message}</div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitDisabled}
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
          <GoogleLogin />
        </div>
      </div>
    </Container>
  );
}
