import React, { useState } from "react";
import { useGoogleLogin } from "react-google-login";
import RestService from "./../../Restservice";
import { createBrowserHistory } from "history";
import Loader from "./../../component/Loader";

const clientId =
  "330063357076-a7b6eqs86ddb9vh53udluovcdn07rvcq.apps.googleusercontent.com";

function GoogleLoginBoard() {
  const [Loading, setLoading] = useState(false);

  const history = createBrowserHistory({ forceRefresh: true });

  const onSuccess = (response) => {
    let fdata = new FormData();
    fdata.append("googleId", response?.profileObj?.googleId);
    fdata.append("imageUrl", response?.profileObj?.imageUrl);
    fdata.append("name", response?.profileObj?.name);
    fdata.append("email", response?.profileObj?.email);
    RestService.GoogleLoginService(function (res) {
      const respo = JSON.parse(res);
      setLoading(false);
      if (respo.data.status === 1) {
        localStorage.setItem(
          "userdata",
          JSON.stringify(respo.data.userDetails, null)
        );
        history.push("/");
      }
    }, fdata);
  };

  const onFailure = (res) => {
    //alert(`Failed to login. 😢 Please try again.`);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    accessType: "offline",
  });

  return (
    <>
      {Loading ? <Loader /> : ""}

      <button
        onClick={signIn}
        className="btn btn-large btn-block googleLoginBtn"
      >
        <img
          src={require("../icons/google.svg").default}
          alt="google login"
          className="googleicon"
        ></img>

        <span className="buttonText">Sign in with Google</span>
      </button>
    </>
  );
}

export default GoogleLoginBoard;
